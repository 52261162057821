function testJS(test) { alert(test); console.log(test); }



function takaisin() {
  window.history.back();
}



jQuery( document ).ready( function() {
  replaceWithSvg();
});





/***********************
*   REPLACE WITH SVG   *
***********************/
function replaceWithSvg() {
  jQuery('img.svg').each(function() {
      var $img = jQuery(this);
      var imgID = $img.attr('id');
      var imgClass = $img.attr('class');
      var imgURL = $img.attr('src');
      var imgFill = $img.data('fill');

      jQuery.get(imgURL, function(data) {
          var $svg = jQuery(data).find('svg');
          if( typeof imgID !== 'undefined' ) { $svg = $svg.attr('id', imgID); }
          if( typeof imgClass !== 'undefined' ) { $svg = $svg.attr('class', imgClass+' replaced-svg'); }
          $svg = $svg.removeAttr('xmlns:a');
          if( !$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width') ) { $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width') ) }
          if ( imgFill !== '' ) { $svg.attr('fill', imgFill); }
          $img.replaceWith($svg);
      }, 'xml');

  });
}



/********************
*   SMOOTH SCROLL   *
********************/
(function ($) {
$('a[href*="#"]')
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    if ( location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname ) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');  
      if (target.length) {
        event.preventDefault();
        var ww = jQuery(window).width(); 
        if (ww <= 959) { 
          $('html, body').animate({
            scrollTop: target.offset().top - 60
          }, 1000);
        } else {
          $('html, body').animate({
            scrollTop: target.offset().top - 120
          }, 1000);
        }
        $(target).focus();
      }
    }
  });
})(window.jQuery);





/**************
*   SWIPERS   *
**************/
var heroSwiper = new Swiper('.swiper-container--hero', {
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  },

  loop: true,
  effect: 'fade',
  spaceBetween: 300,
  allowTouchMove: false,

  autoplay: {
    delay: 10000,
    disableOnInteraction: false,
  },

  on: {
    init: function () {
      startHeroTimer()
    },

    slideChange: function () {
      resetHeroTimer()
    },
  },
})

function toggleAutoplay() {
  if ( heroSwiper.autoplay.running === true ) {
    heroSwiper.autoplay.stop();
    jQuery('#heroswipericon').addClass('open');
    disableHeroTimer();
  } else {
    heroSwiper.autoplay.start();
    jQuery('#heroswipericon').removeClass('open');
    startHeroTimer();
  }
}

function startHeroTimer() {
  jQuery('#herotimerbar').css("width", "100%");
}

function resetHeroTimer() {
  jQuery('#herotimerbar').addClass('disabletransition');
  jQuery('#herotimerbar').css("width", "0%");
  jQuery('#herotimerbar')[0].offsetWidth;
  jQuery('#herotimerbar').removeClass('disabletransition');
  jQuery('#herotimerbar').css("width", "100%");
}

function disableHeroTimer() {
  jQuery('#herotimerbar').addClass('disabletransition');
  jQuery('#herotimerbar').css("width", "0%");
  jQuery('#herotimerbar')[0].offsetWidth;
  jQuery('#herotimerbar').removeClass('disabletransition');
}





function toggleOpen(eleID, lockBody, current, main) {
  eleID = '#' + eleID;
  if ( jQuery(eleID).hasClass('open') ) {
    jQuery(current).removeClass('open');
    jQuery(eleID).removeClass('open');
    if (lockBody !== 0) {
      jQuery('body').removeClass('locked' + lockBody);
    }
    if (main !== 0) {
      jQuery('main').removeClass('open');
    }
  } else {
    jQuery(current).addClass('open');
    jQuery(eleID).addClass('open');
    if (lockBody !== 0) {
      jQuery('body').addClass('locked' + lockBody);
    }
    if (main !== 0) {
      jQuery('main').addClass('open');
    }
  }
}


jQuery( document ).ready( function() {
  jQuery('#header').addClass('transition-ready');
  jQuery('main').addClass('transition-ready');
  jQuery('footer').addClass('transition-ready');

  jQuery('.main.open').click( function(e) {
    console.log('main.open');
    toggleNav();
  });
});



function mainClick(ele) {
  if ( jQuery(ele).hasClass('open') ) {
    toggleNav();
  }
}



function toggleNav() {
  if (!jQuery('#header').hasClass('open')) {
    jQuery('#header').addClass('open');
    jQuery('main').addClass('open');
    jQuery('footer').addClass('open');
    jQuery('.toggle__navigation').addClass('open');
    jQuery('html').addClass('open');
  } else {
    jQuery('#header').removeClass('open');
    jQuery('main').removeClass('open');
    jQuery('footer').removeClass('open');
    jQuery('.toggle__navigation').removeClass('open');
    jQuery('html').removeClass('open');
  }
}



function toggleLang() {
  if (!jQuery('#lang-overlay').hasClass('open')) {
    jQuery('#lang-overlay').addClass('open');
    jQuery('html').css('overflow-y', 'hidden');
  } else {
    jQuery('#lang-overlay').removeClass('open');
    jQuery('html').css('overflow-y', 'auto');
  }
}



function toggleSearch() {
  if (!jQuery('#search-overlay').hasClass('open')) {
    jQuery('#search-overlay').addClass('open');
    jQuery('html').css('overflow-y', 'hidden');
  } else {
    jQuery('#search-overlay').removeClass('open');
    jQuery('html').css('overflow-y', 'auto');
  }
}



// ACCESSIBILITY FOR KEYBOARD USE: OPEN MOBILE NAVIGATION
jQuery(".toggle").keyup(function(event) {
  if (event.keyCode === 13) { // 13 => Enter
    jQuery(this).click();
  }
});



/********************
*   DROPDOWN MENU   *
********************/
jQuery('.dropdown-toggle').each(function(){
  jQuery(this).removeAttr('href');
  jQuery(this).attr('tabindex', 0);
  jQuery(this).keyup(function(e) {
    if (e.keyCode === 13) { // 13 => Enter
      jQuery(this).click();
    }
  });
});



jQuery(document).click(function(){
 jQuery(".dropdown-menu.open").removeClass('open');
 jQuery(".dropdown.open").removeClass('open');
});

jQuery(".dropdown").click(function(e){
  e.stopPropagation();
});

jQuery( ".dropdown-toggle" ).click(function() {
  jQuery(this).parent().toggleClass('open');
  jQuery(this).siblings('.dropdown-menu').toggleClass('open');
});

jQuery(".dropdown").on('mouseleave', function(e) {
  if (jQuery(window).width() > 960) {
    jQuery(this).children('.dropdown-menu').removeClass('open');
    jQuery(this).children('.dropdown-toggle').removeClass('open');
  }
});

jQuery(".nav-lvl-0>a").on('focus', function(e) {
  jQuery('.dropdown-menu').removeClass('open');
  jQuery('.dropdown-toggle').removeClass('open');
});




function avaaHaitari(ele) {
  jQuery(ele).parent().toggleClass('open');
}




jQuery(window).on('load', function() {
  jQuery(".timeline").each(function(index, element){
    jQuery(this).addClass("swinstance-" + index);
    jQuery(this).find(".swiper-button-prev").addClass("btn-prev-" + index);
    jQuery(this).find(".swiper-button-next").addClass("btn-next-" + index);
    var swiper = new Swiper(".swinstance-" + index, {
    navigation: {
        nextEl: ".btn-next-" + index,
        prevEl: ".btn-prev-" + index
    },
    });
  });
});